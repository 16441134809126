<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import user from '@/api/user' 
import api from '@/api/api' 
import DefaultLayout from '@/layouts/default.vue'
import AuthLayout from '@/layouts/auth.vue'
export default {
  components: {
    DefaultLayout,
    AuthLayout,
  },
  data() {
    return {
      auth: [1, 2, 3]
    }
  },
  computed: {
    layout() {
      console.log(this.$route.meta.layout || 'default') + '-layout'
      return (this.$route.meta.layout || 'default') + '-layout'
    },
  },
  mounted() {    
    this.Auth()
  },
  methods: {
    Auth() {
      const auth = localStorage.getItem('token')
      console.log('auth', auth)
      if (auth == null) {
        this.$router.push('/login')
      } else {
        api.getUser().then(res => {
          if (!this.auth.includes(res.data.role)) {
            this.$router.push('/login')
            localStorage.removeItem('token')
            localStorage.removeItem('refresh')
          }
        }).catch(err => {
          console.log(err)
          user.RefreshToken().then(res => {
            console.log(res)
            localStorage.setItem('token', res.data.access)
            localStorage.setItem('refresh', res.data.refresh)
            location.reload()
          }).catch(err => {
            console.log(err)
            this.$router.push('/login')
          })
        })
      }
    },
  }
} 
</script>
<style>
@import 'assets/css/style.css';
/* @import 'assets/css/styleshet.css'; */
@import 'assets/bootstrap/css/bootstrap.css';
@import 'assets/bootstrap/css/bootstrap-grid.css';
@import 'assets/bootstrap/css/bootstrap-reboot.css';
@import 'assets/bootstrap/css/bootstrap-utilities.css';
@import 'assets/bootstrap-icons/bootstrap-icons.css';
@import 'assets/remixicon/remixicon.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
