<template>
  <div class="about login">
    <div class="login-block card info-card sales-card">
      <h1>Вход</h1>
      <form action="" @submit.prevent="login">
        <input v-model="user" placeholder="Логин" v-mask="['+7 (###) ###-##-##', '+7 (###) ###-##-##']">
        <input v-model="password" placeholder="Пароль">
        <input type="submit" class="submit" value="Отправить">
      </form>
      <span style="color: red" v-if="err">Неверные данные</span>
    </div>
  </div>
</template>

<script>
import user from '@/api/user' 
export default {
  name: "AuthView",
  data() {
    return {
      user: '',
      password: '',
      // user: 'admin',
      // password: 'admin',
    }
  },

  methods: {
    login() {
      let login = {
        username: this.user,
        password: this.password,
      }
      user.login(login).then(res => {
        localStorage.setItem('token', res.data.access)
        localStorage.setItem('refresh', res.data.refresh)
        window.location.href = '/';
      })
    }
  }
};
  
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100vh;
  .card {
    padding: 25px;
    .submit {
      background: #7c69ef;
      color: #fff;
      font-weight: 700;
    }
    h1 {
      margin-bottom: 25px;
    }
    input {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.175);

    }
  }
}
</style>