<template>
  <section class="section dashboard">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="card-body_top">
                  <h5 class="card-title">Изменить Банер</h5>
                </div>
                <form action="" @submit.prevent="Send()">
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label"
                      >Название</label
                    >
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.title"
                      />
                      <span
                        style="color: red; display: block"
                        v-if="errorMessage.title"
                      >
                        {{ errorMessage.title[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label"
                      >Описание главного банера</label
                    >
                    <div class="col-sm-10">
                      <textarea
                        class="form-control"
                        v-model="item.sub_title"
                        style="height: 100px"
                      ></textarea>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label"
                      >Категория</label
                    >
                    <div class="col-sm-10">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        v-model="item.category_id"
                      >
                        <!-- <option selected="">Open this select menu</option> -->
                        <option
                          :value="e.slug"
                          v-for="e in catgory"
                          :key="e.id"
                        >
                          {{ e.name }}
                        </option>
                      </select>
                      <!-- <input
                        type="text"
                        class="form-control"
                        v-model="item.link"
                      /> -->
                    </div>
                  </div>
                  <!-- <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label"
                      >Мобильное приложение каталог </label
                    >
                    <div class="col-sm-10">
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.category_id"
                      />
                    </div>
                  </div> -->
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label"
                      >Изображение</label
                    >
                    <div class="col-sm-10">
                      <input
                        class="form-control"
                        @change="getFile"
                        type="file"
                        id="formFile"
                      />
                      <img :src="item.main_image" alt="" class="image-block" />
                      <span
                        style="color: red; display: block"
                        v-if="errorMessage.main_image"
                      >
                        {{ errorMessage.main_image[0] }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-sm-2 col-form-label"></label>
                    <div class="col-sm-10">
                      <button
                        :disabled="disabled"
                        type="submit"
                        class="btn btn-primary"
                      >
                        Создать
                      </button>
                      <img
                        src="@/assets/Spinner-1s-200px.gif"
                        alt="gif"
                        style="height: 38px; width: 38px"
                        v-if="disabled"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api/api";
// import axios from 'axios'

export default {
  name: "BanerEdit",
  components: {},
  data() {
    return {
      disabled: false,
      errorMessage: "",
      item: "",
      file: "",
      catgory: "",
    };
  },
  mounted() {
    api.getBanerID(this.$route.params.id).then((res) => (this.item = res.data));
    api.getCategorys().then((res) => {
      this.catgory = res.data.results;
      if (res.data.next) {
        this.getNext(res.data.next);
      }
    });
  },
  methods: {
    Send() {
      this.disabled = true;
      let formData = new FormData();
      formData.append("title", this.item.title);
      formData.append("sub_title", this.item.sub_title);
      formData.append("link", this.item.category_id);
      formData.append("category_id", this.item.category_id);

      if (this.file[0]) {
        formData.append("main_image", this.file[0]);
      } else {
        if (this.item.main_image) {
          formData.append("main_image", this.item.main_image);
        }
      }
      api
        .putBaner(this.$route.params.id, formData)
        .then((res) => {
          this.disabled = false;
          console.log(res);
          this.$router.push("/baner");
        })
        .catch((err) => {
          this.disabled = false;
          this.errorMessage = err.response.data;
          console.log(err, "iubibwibiwbeibweibgibib");
        });
    },
    getFile(e) {
      this.file = e.target.files || e.dataTransfer.files;
      console.log("file", this.file);
    },
    getNext(link) {
      api.getNextLink(link).then((res) => {
        res.data.results.forEach((e) => {
          this.catgory.push(e);
        });
        if (res.data.next) {
          this.getNext(res.data.next);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.image-block {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 16px 0;
}
</style>
