import axios from "axios";
// import authHeader from "./auth-header";

const API_URL = "https://newapi.kostyum.kz/api/";
// const API_URL = "http://localhost:8000/api/";
// const API_URL = "https://backend-kostum.zoom-app.kz/api/";

class UserService {
  login(login) {
    return axios.post(API_URL + "user/token/", login)
  }
  RefreshToken() {
    let refresh = {
      refresh: localStorage.getItem('refresh')
    }
    return axios.post(API_URL + "user/token/refresh/", refresh);
  }
}

export default new UserService();
