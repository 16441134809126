import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import ProductView from '../views/ProductView.vue'
import AuthView from "../views/AuthView.vue";
import InvoiceView from "../views/Invoice.vue";
import Baner from "../views/Home/Baner/Index.vue";
import BanerEdit from "../views/Home/Baner/Edit.vue";
import BanerAdd from "../views/Home/Baner/Add.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    meta: { layout: "default" },
    component: HomeView,
  },
  {
    path: "/menu",
    name: "MenuView",
    meta: { layout: "default" },
    component: () => import("../components/sidebar.vue"),
  },
  {
    path: "/invoice",
    name: "Invoice",
    meta: { layout: "auth" },
    component: InvoiceView,
  },
  {
    path: "/login",
    name: "auth",
    meta: { layout: "auth" },
    component: AuthView,
  },
  // Бонусная программа
  {
    path: "/bonusrule",
    name: "BonusRule",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/BonusRule.vue"),
  },
  // ProductGroup
  {
    path: "/productsgroup",
    name: "ProductGroupView",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/ProductGroup/Index.vue"),
  },
  {
    path: "/productsgroup/:id",
    name: "ProductGroupEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/ProductGroup/Edit.vue"),
  },
  {
    path: "/productsgroup/add",
    name: "ProductGroupAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/ProductGroup/Add.vue"),
  },
  // Product
  {
    path: "/products",
    name: "ProductsView",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Product/Index.vue"),
  },
  {
    path: "/products/:id",
    name: "ProductEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Product/Edit.vue"),
  },
  {
    path: "/products/add",
    name: "ProductAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Product/Add.vue"),
  },

  // ИМПОРТ ЭКСПОРТ
  {
    path: "/export",
    name: "ProductExport",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Export/Index.vue"),
  },
  {
    path: "/import",
    name: "ProductImport",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Import/Index.vue"),
  },

  // Categorys
  {
    path: "/categorys",
    name: "Categorys",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Category/Index.vue"),
  },
  {
    path: "/categorys/add",
    name: "CategorysAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Category/Add.vue"),
  },
  {
    path: "/categorys/:id",
    name: "CategorysEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Category/Edit.vue"),
  },
  // Filter
  {
    path: "/filters",
    name: "Filter",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Filter/Index.vue"),
  },
  {
    path: "/filter/add",
    name: "FilterAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Filter/Add.vue"),
  },
  {
    path: "/filter/:id",
    name: "FilterEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Filter/Edit.vue"),
  },
  {
    path: "/about",
    name: "AboutView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  // Delivery
  {
    path: "/delivery",
    name: "Delivery",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Delivery/Index.vue"),
  },
  {
    path: "/delivery/:id",
    name: "DeliveryEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Delivery/Edit.vue"),
  },
  {
    path: "/delivery/add",
    name: "DeliveryAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Delivery/Add.vue"),
  },
  // Size
  {
    path: "/size",
    name: "Size",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Size/Index.vue"),
  },
  {
    path: "/size/add",
    name: "SizeAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Size/Add.vue"),
  },
  {
    path: "/size/:id",
    name: "SizeEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Size/Edit.vue"),
  },
  // Cites
  {
    path: "/cites",
    name: "Cites",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Cites/Index.vue"),
  },
  {
    path: "/cites/add",
    name: "CitesAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Cites/Add.vue"),
  },
  {
    path: "/cites/:id",
    name: "CitesEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Cites/Edit.vue"),
  },

  // Color
  {
    path: "/color",
    name: "Color",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Color/Index.vue"),
  },
  {
    path: "/color/add",
    name: "ColorAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Color/Add.vue"),
  },
  {
    path: "/color/:id",
    name: "ColorEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Color/Edit.vue"),
  },

  // Brand
  {
    path: "/brand",
    name: "Brand",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Brand/Index.vue"),
  },
  {
    path: "/brand/add",
    name: "BrandAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Brand/Add.vue"),
  },
  {
    path: "/brand/:id",
    name: "BrandEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Brand/Edit.vue"),
  },

  // Shop
  {
    path: "/shop",
    name: "Shop",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Shop/Index.vue"),
  },
  {
    path: "/shop/add",
    name: "ShopAdd",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Shop/Add.vue"),
  },
  {
    path: "/shop/:id",
    name: "ShopEdit",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/Shop/Edit.vue"),
  },
  // Массовое изменение цен списков
  {
    path: "/edidprice",
    name: "EditPrice",
    meta: { layout: "default" },
    component: () => import("../views/ProductTab/EditPrice/Index.vue"),
  },
  // HOME
  // Baner
  {
    path: "/baner",
    name: "Baner",
    meta: { layout: "default" },
    component: Baner,
  },
  {
    path: "/baner/:id",
    name: "BanerEdit",
    meta: { layout: "default" },
    component: BanerEdit,
  },
  {
    path: "/baner/add",
    name: "BanerAdd",
    meta: { layout: "default" },
    component: BanerAdd,
  },
  // SecondBaner
  {
    path: "/secondbaner",
    name: "SecondBaner",
    meta: { layout: "default" },
    component: () => import("../views/Home/SecondBaner/Index.vue"),
  },
  {
    path: "/secondbaner/:id",
    name: "SecondBanerEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/SecondBaner/Edit.vue"),
  },
  {
    path: "/secondbaner/add",
    name: "SecondBanerAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/SecondBaner/Add.vue"),
  },
  // Lists
  {
    path: "/lists",
    name: "Lists",
    meta: { layout: "default" },
    component: () => import("../views/Home/Lists/Index.vue"),
  },
  {
    path: "/lists/:id",
    name: "ListsEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/Lists/Edit.vue"),
  },
  {
    path: "/lists/add",
    name: "ListsAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/Lists/Add.vue"),
  },
  // HomeBlock
  {
    path: "/homeblock",
    name: "HomeBlock",
    meta: { layout: "default" },
    component: () => import("../views/Home/HomeBlock/Index.vue"),
  },
  {
    path: "/homeblock/:id",
    name: "HomeBlockEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/HomeBlock/Edit.vue"),
  },
  {
    path: "/homeblock/add",
    name: "HomeBlockAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/HomeBlock/Add.vue"),
  },
  // Advantage
  {
    path: "/advantage",
    name: "Advantage",
    meta: { layout: "default" },
    component: () => import("../views/Home/Advantage/Index.vue"),
  },
  {
    path: "/advantage/:id",
    name: "AdvantageEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/Advantage/Edit.vue"),
  },
  {
    path: "/advantage/add",
    name: "AdvantageAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/Advantage/Add.vue"),
  },

  // Blog
  {
    path: "/blogs",
    name: "Blogs",
    meta: { layout: "default" },
    component: () => import("../views/Blog/Index.vue"),
  },
  {
    path: "/blog/:id",
    name: "BlogEdit",
    meta: { layout: "default" },
    component: () => import("../views/Blog/Edit.vue"),
  },
  {
    path: "/blog/add",
    name: "BlogAdd",
    meta: { layout: "default" },
    component: () => import("../views/Blog/Add.vue"),
  },

  // Album
  {
    path: "/album",
    name: "Album",
    meta: { layout: "default" },
    component: () => import("../views/Album/Index.vue"),
  },
  {
    path: "/album/:id",
    name: "AlbumEdit",
    meta: { layout: "default" },
    component: () => import("../views/Album/Edit.vue"),
  },
  {
    path: "/album/add",
    name: "AlbumAdd",
    meta: { layout: "default" },
    component: () => import("../views/Album/Add.vue"),
  },
  // Главная
  // {
  //   path: '/blog/:id',
  //   name: 'BlogEdit',
  //   meta: {layout: 'default'},
  //   component: () => import('../views/Blog/Edit.vue')
  // },
  {
    path: "/advantage/add",
    name: "AdvantageAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/Advantage/Add.vue"),
  },

  //Other Pages

  //Schedule
  {
    path: "/schedule",
    name: "Schedule",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Schedule/Index.vue"),
  },

  {
    path: "/schedule/add",
    name: "ScheduleAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Schedule/Add.vue"),
  },

  {
    path: "/schedule/edit/:id",
    name: "ScheduleEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Schedule/Edit.vue"),
  },
  //Schedule

  //about
  {
    path: "/aboutUs",
    name: "AboutUs",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/AboutUs/Index.vue"),
  },

  {
    path: "/aboutUs/edit/:id",
    name: "AboutUsEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/AboutUs/Edit.vue"),
  },

  {
    path: "/aboutUs/add",
    name: "AboutUsAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/AboutUs/Add.vue"),
  },

  //about

  //partners
  {
    path: "/partners",
    name: "Partners",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Partners/Index.vue"),
  },

  {
    path: "/partners/edit/:id",
    name: "PartnersEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Partners/Edit.vue"),
  },

  {
    path: "/partners/add",
    name: "PartnersAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Partners/Add.vue"),
  },

  //partners

  //politics
  {
    path: "/politics",
    name: "Politics",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Politics/Index.vue"),
  },

  {
    path: "/politics/edit/:id",
    name: "PoliticsEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Politics/Edit.vue"),
  },

  {
    path: "/politics/add",
    name: "PoliticsAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Politics/Add.vue"),
  },
  //politics

  //oferta
  {
    path: "/oferta",
    name: "Oferta",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Oferta/Index.vue"),
  },

  {
    path: "/oferta/add",
    name: "OfertaAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Oferta/Add.vue"),
  },

  {
    path: "/oferta/edit/:id",
    name: "OfertaEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Oferta/Edit.vue"),
  },
  //oferta

  //trends
  {
    path: "/trends",
    name: "Trends",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Trends/Index.vue"),
  },

  {
    path: "/trends/edit/:id",
    name: "TrendsEdit",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Trends/Edit.vue"),
  },

  {
    path: "/trends/add",
    name: "TrendsAdd",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Trends/Add.vue"),
  },
  //trends

  //phone
  {
    path: "/phone",
    name: "Phone",
    meta: { layout: "default" },
    component: () => import("../views/Home/Phone/Index.vue"),
  },

  {
    path: "/phone/edit/:id",
    name: "PhoneEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/Phone/Edit.vue"),
  },

  {
    path: "/phone/add",
    name: "PhoneAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/Phone/Add.vue"),
  },
  //phone

  //ORDERS
  {
    path: "/order",
    name: "Order",
    meta: { layout: "default" },
    component: () => import("../views/Order/Index.vue"),
  },

  {
    path: "/order/edit/:id",
    name: "OrderEdit",
    meta: { layout: "default" },
    component: () => import("../views/Order/Edit.vue"),
  },

  {
    path: "/order/add",
    name: "OrderAdd",
    meta: { layout: "default" },
    component: () => import("../views/Order/Add.vue"),
  },
  //main_information
  {
    path: "/info",
    name: "Info",
    meta: { layout: "default" },
    component: () => import("../views/Home/Main_Information/Index.vue"),
  },

  {
    path: "/info/edit/:id",
    name: "InfoEdit",
    meta: { layout: "default" },
    component: () => import("../views/Home/Main_Information/Edit.vue"),
  },

  {
    path: "/info/add",
    name: "InfoAdd",
    meta: { layout: "default" },
    component: () => import("../views/Home/Main_Information/Add.vue"),
  },
  //USER
  {
    path: "/user",
    name: "User",
    meta: { layout: "default" },
    component: () => import("../views/User/Index.vue"),
  },

  {
    path: "/user/edit/:id",
    name: "UserEdit",
    meta: { layout: "default" },
    component: () => import("../views/User/Edit.vue"),
  },

  {
    path: "/user/add",
    name: "UserAdd",
    meta: { layout: "default" },
    component: () => import("../views/User/Add.vue"),
  },
  //main_information
  {
    path: "/order-and-delivery",
    name: "OrderDelivery",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/OrderDelivery.vue"),
  },
  {
    path: "/discount-system",
    name: "DiscountSystem",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/DiscountSystem.vue"),
  },
  {
    path: "/gift-certificate",
    name: "GiftCertificate",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/GiftCertificate.vue"),
  },
  {
    path: "/delivery-terms",
    name: "DeliveryTerms",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/DeliveryTerms.vue"),
  },
  {
    path: "/product-return",
    name: "ProductReturn",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/ProductReturn.vue"),
  },
  {
    path: "/rent",
    name: "Rent",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Rent.vue"),
  },
  {
    path: "/review",
    name: "Review",
    meta: { layout: "default" },
    component: () => import("../views/Review/Index.vue"),
  },
  {
    path: "/review/:id",
    name: "ReviewEdit",
    meta: { layout: "default" },
    component: () => import("../views/Review/Edit.vue"),
  },
  {
    path: "/gatant",
    name: "Gatant",
    meta: { layout: "default" },
    component: () => import("../views/OtherPages/Gatant.vue"),
  },
  {
    path: "/applications",
    name: "Applications",
    meta: { layout: "default" },
    component: () => import("../views/Form/Applications.vue"),
  },
  {
    path: "/applications/email",
    name: "ApplicationsEmail",
    meta: { layout: "default" },
    component: () => import("../views/Form/Email.vue"),
  },
  {
    path: "/applications/Partner",
    name: "ApplicationsPartner",
    meta: { layout: "default" },
    component: () => import("../views/Form/Partner.vue"),
  },
  // {
  //   path: "/phone",
  //   name: "Phone",
  //   meta: { layout: "default" },
  //   component: () => import("../views/OtherPages/Phone/Index.vue"),
  // },

  // {
  //   path: "/phone/:id",
  //   name: "PhoneEdit",
  //   meta: { layout: "default" },
  //   component: () => import("../views/OtherPages/Phone/Edit.vue"),
  // },

  // {
  //   path: "/phone/add",
  //   name: "PhoneAdd",
  //   meta: { layout: "default" },
  //   component: () => import("../views/OtherPages/Phone/Add.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
