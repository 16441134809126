<template>
  <section class="section dashboard">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <!-- Reports -->
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- {{ categorys }} -->
                <div class="card-body_top">
                  <h5 class="card-title">Главные банеры</h5>
                  <router-link :to="{ name: 'BanerAdd' }" class="card-btn"
                    >Добавить</router-link
                  >
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th scope="col">ID</th>
                      <th scope="col">Название</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="items">
                    <tr v-for="item in items" :key="item.id">
                      <th><input type="checkbox" /></th>
                      <th scope="row">{{ item.id }}</th>
                      <td>{{ item.title }}</td>
                      <td></td>
                      <td class="icon">
                        <router-link
                          :to="{ name: 'BanerEdit', params: { id: item.id } }"
                        >
                          <i class="ri-ball-pen-fill"></i>
                        </router-link>
                        <i @click="delBaner(item.id)" class="ri-close-fill"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api/api";
// import axios from 'axios'

export default {
  name: "BanerView",
  components: {},
  data() {
    return {
      items: "",
    };
  },
  methods: {
    delBaner(id) {
      if (confirm("Вы точно хотите удалить?")) {
        api
          .delBaner(id)
          .then(() => {
            api.getBaner().then((res) => (this.items = res.data.results));
          })
          .catch(() => {
            alert("Не удалось удалить");
          });
      }
    },
  },
  created() {
    api.getBaner().then((res) => (this.items = res.data.results));
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.main_img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.icon {
  text-align: right;
  .ri-close-fill {
    color: red;
  }
  i {
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
  }
}
.card-body_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .card-btn {
    cursor: pointer;
    align-items: center;
    background: #7c69ef;
    border-radius: 30px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding: 16px 32px;
    &:hover {
      background: #6959cc;
      cursor: pointer;
    }
  }
}
</style>
