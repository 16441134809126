<template>
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav" v-if="admin">
      <li class="nav-item">
        <router-link :to="{ name: 'HomeView' }" class="nav-link">
          <i class="bi bi-grid"></i>
          <span>Главная</span>
        </router-link>
      </li>
      <!-- Товары -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Товары')">
          <i class="bi bi-menu-button-wide"></i><span>Товары</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Товары' }"
        >
          <li>
            <router-link
              :to="{ name: 'ProductGroupView' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span>Карточки товаров</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ProductsView' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Продукты</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Categorys' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Категории</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Filter' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Фильтры</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Color' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Цвета</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Size' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Размер</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Cites' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Города</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Shop' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Магазины/Склады</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Brand' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Бренды</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Delivery' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Доставка</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ProductExport' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span> Выгрузки Эксель</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ProductImport' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Импорт Эксель</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'EditPrice' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Массовое изменение цен списков</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'BonusRule' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Правила начисления бонусов</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- Товары -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Заказы')">
          <i class="bi bi-menu-button-wide"></i><span>Заказы</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Заказы' }"
        >
          <li>
            <router-link :to="{ name: 'Order' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Заказы</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ApplicationsEmail' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Email для заказов</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!--  -->
      
      <!-- <li class="nav-item">
        <div class="nav-link" @click="setmenu('Заявки')">
          <i class="bi bi-menu-button-wide"></i><span>Заявки</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Заявки' }"
        >
          <li>
            <router-link :to="{ name: 'Applications' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Заявки</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ApplicationsPartner' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Заявки партнеры</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'ApplicationsEmail' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Email</span>
            </router-link>
          </li>
        </ul>
      </li> -->
      <!--  -->
      <li class="nav-item">
        <router-link :to="{ name: 'Review' }" class="nav-link">
          <i class="bi bi-menu-button-wide"></i><span>Отзывы</span>
        </router-link>
      </li>
      <!-- Заказы -->

      <!-- Доставка -->
      <!-- Категории товаров -->
      <!-- Пользователи -->
      <!-- Система бонусов -->
      <li class="nav-heading">Страницы</li>
      <!-- Главная страница -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Главная страница')">
          <i class="bi bi-menu-button-wide"></i><span>Главная страница</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Главная страница' }"
        >
          <li>
            <router-link :to="{ name: 'Baner' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Главные банеры</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Advantage' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Преимущества</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'HomeBlock' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Блоки на главной странице</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'SecondBaner' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span>Дополнительные банеры</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Info' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Основная информация</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Lists' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Списки</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Phone' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Телефоны</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- Главная страница -->
      <!-- Пользователи -->
      <li class="nav-item">
        <router-link :to="{ name: 'User' }" class="nav-link">
          <i class="bi bi-menu-button-wide"></i><span>Пользователи</span>
        </router-link>
      </li>
      <!-- Пользователи -->
      <!-- Блог -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Блог')">
          <i class="bi bi-menu-button-wide"></i><span>Блог</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Блог' }"
        >
          <li>
            <router-link :to="{ name: 'Blogs' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Посты</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'BlogAdd' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Добавить</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- Блог -->
      <!-- Фотоальбом -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Фотоальбом')">
          <i class="bi bi-menu-button-wide"></i><span>Фотоальбом</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Фотоальбом' }"
        >
          <li>
            <router-link :to="{ name: 'Album' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Альбомы</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- Фотоальбом -->
      <!-- Другие страницы -->
      <li class="nav-item">
        <div class="nav-link" @click="setmenu('Другие страницы')">
          <i class="bi bi-menu-button-wide"></i><span>Другие страницы</span>
        </div>
        <ul
          id="components-nav"
          class="nav-content collapse"
          :class="{ show: menu == 'Другие страницы' }"
        >
          <li>
            <router-link :to="{ name: 'Schedule' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>График работы</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'AboutUs' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>О нас</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Partners' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Партнеры</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Politics' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span style="text-align: start"
                >Политика конфиденциальности</span
              >
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Oferta' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i
              ><span>Публичная Оферта</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Trends' }"  @click="delclass()" class="nav-link collapsed">
              <i class="bi bi-menu-button-wide"></i><span>Тренды</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'DeliveryTerms' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span>Условия доставки</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'GiftCertificate' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span>Подарочные сертификаты</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'DiscountSystem' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Система скидок</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'OrderDelivery' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i
              ><span>Заказ и доставка</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ProductReturn' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Возврат</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Rent' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Аренда костьюма</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Gatant' }"
               @click="delclass()" class="nav-link collapsed"
            >
              <i class="bi bi-menu-button-wide"></i><span>Гарантия качества</span>
            </router-link>
          </li>
        </ul>
      </li>
      <!-- Другие страницы -->
      <!-- Выход -->
      <li class="nav-item">
        <a @click="logout()" class="nav-link collapsed" >
          <i class="bi bi-box-arrow-in-right"></i>
          <span>Выйти</span>
        </a>
      </li>
      <!-- Выход -->
    </ul>
    <ul class="sidebar-nav" id="sidebar-nav" v-else>
      <!-- Товары -->
      <li class="nav-item">
        <router-link :to="{ name: 'OrderAdd' }"  @click="delclass()" class="nav-link">
          <i class="bi bi-menu-button-wide"></i><span>Создать заказ</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Order' }" @click="delclass()" class="nav-link">
          <i class="bi bi-menu-button-wide"></i><span>Заказы</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a   class="nav-link collapsed" @click="logout()">
          <i class="bi bi-box-arrow-in-right"></i>
          <span>Выйти</span>
        </a>
      </li>
      <!-- Выход -->
    </ul>
  </aside>
</template>

<script>
import api from '@/api/api' 
export default {
  name: "HelloWorld",
  data() {
    return {
      site: false,
      mob: false,
      menu: "Товары",
      admin: false,
    };
  },
  props: {
    msg: String,
  },
  mounted() {    
    api.getUser().then(res => {
      if (res.data.role == 1) {
        this.admin = true
      }
    })
  },
  methods: {
    setmenu(item) {
      if (this.menu == item) {
        this.menu = "";
      } else {
        this.menu = item;
      }
    },
    logout() {
      localStorage.removeItem("token");
      window.location.href = "/login";
    },
    delclass() {
      document.body.classList.remove('toggle-sidebar');
    }
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  text-align: left;
}
a {
  color: #42b983;
}
.nav-link {
  cursor: pointer;
}

</style>
