<template>
    <section class="section dashboard">
      <div v-html="invoice"></div>
    </section>
</template>

<script>


export default {
  name: 'HomeView',
  data() {
    return {
      invoice: ''
    }
  },
  components: {
  },
  mounted() {    
    this.invoice = this.$route.query.invoice
    
    setTimeout(function() {
      window.print()
    }, 500);
  },


}
</script>
<style lang="scss" scoped>

section {
  max-width: 1140px;
  padding: 20px;
  margin: auto;
}
</style>