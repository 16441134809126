<template>
    <div class="home_layouts">
        <Header />
        <Sidebar />
        <main id="main" class="main">
            <router-view />
        </main>
    </div>
</template>
<script>
import Sidebar from '@/components/sidebar.vue'
import Header from '@/components/header'

export default {
  name: 'DefaultLayout',
  components: {
    Sidebar,
    Header,
  }
}
</script>
