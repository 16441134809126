<template>
    <div class="auth_layouts">
        <router-view />
    </div>
</template>
<script>
export default {
  name: 'AuthLayout',
  components: {
  }
}
</script>